<template>
  <div style="margin: 0 auto;text-align: center;">
    <h2>企业微信绑定QC系统账号</h2>
    <el-form ref="form" style="padding: 10px;" :model="form">
            <el-form-item >
              <el-input style="width: 300px;" v-model="form.User" clearable :placeholder="this.$t('login.user')"></el-input>
            </el-form-item>
            <el-form-item >
              <el-input style="width: 300px;" show-password v-model="form.Password" @keyup.enter.native.native="qywxLogin(form)" :placeholder="this.$t('login.password')"></el-input>
            </el-form-item>
          </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Userbool()">{{ $t('All.取消') }}</el-button>
        <el-button type="primary" @click="qywxLogin(form)">{{ $t('All.确定') }}</el-button>
      </div><div class="."></div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      form: {
        User: '',
        Password: ''
      },
      dialogFormVisible1: true,
      grouplist: [],
      wxlist: []
    }
  },
  props: ['Userid', 'Boollogin', 'Userbool'], // 接手psMsg值
  created () {
  },
  mounted () {
    this.$message.error('企业微信未绑定账号')
  },
  methods: {
    ...mapMutations(['Userediet', 'loginlist', 'ProductnIsloginfun']),
    handleCloseuser (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.Userbool()
        })
        .catch(_ => {})
    },
    async qywxLogin (row) {
      try {
        const { data: res } = await this.$http.post('/api/Authenticate/login', row)
        if (res.status !== 200) return this.$message.error(this.$t('login.loginmessageno'))
        const { data: ress } = await this.$http.get(`/api/Authenticate/Getbangdingid?user=${this.form.User}&id=${this.Userid}`)
        if (ress.status !== 200) return this.$message.error('绑定企业微信失败')
        this.$message.success('绑定企业微信成功！')
        this.loginlist(ress.Userdata)
        window.sessionStorage.setItem('token', ress.token)
        this.ProductnIsloginfun(true)
        this.Userbool()
      } catch (error) {
        alert('网络异常')
      }
    }
  }
}
</script>
<style lang="less" scoped>
</style>
